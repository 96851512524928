import {
  QueryClient,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

import { Options, SearchParamsOption } from 'ky';

import { fetchCategories, getCurrentUserApps, MyAppsParams } from '@api';

import { AUTH_USER_QUERY_KEY } from './keys';

const myAppsQueryKeys = {
  myApps: (searchParams?: MyAppsParams) => [AUTH_USER_QUERY_KEY, 'myApps', searchParams],
  categories: ['categories'],
};

export const prefetchMyApps = (
  clientQuery: QueryClient,
  options: { searchParams: MyAppsParams },
) => {
  return clientQuery.prefetchQuery({
    queryKey: myAppsQueryKeys.myApps(),
    queryFn: () => getCurrentUserApps({ searchParams: options.searchParams }),
  });
};

export const prefetchCategoriesQuery = (clientQuery: QueryClient, options?: Options) => {
  return clientQuery.prefetchQuery({
    queryKey: myAppsQueryKeys.categories,
    queryFn: () => fetchCategories(options),
  });
};

export const useMyApps = (
  params?: MyAppsParams,
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getCurrentUserApps>>,
      unknown,
      unknown,
      any,
      { nextOffset: number }
    >,
    'enabled'
  >,
) => {
  return useInfiniteQuery({
    queryKey: myAppsQueryKeys.myApps(params),
    queryFn: ({ pageParam }) => {
      return getCurrentUserApps({
        searchParams: {
          ...params,
          limit: params?.limit,
          offset: pageParam?.nextOffset || 0,
        },
      });
    },
    initialPageParam: { nextOffset: 0 },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.data?.length === params?.limit
        ? {
            nextOffset: pages?.length * params?.limit,
          }
        : undefined;
    },
    ...options,
  });
};

export const useCategoriesQuery = (searchParams?: SearchParamsOption) => {
  return useQuery({
    queryKey: myAppsQueryKeys.categories,
    queryFn: () => fetchCategories({ searchParams }),
  });
};
